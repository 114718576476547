import logo from "./logo.svg";
import "./App.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import HashLoader from "react-spinners/HashLoader";

function Header() {
  return (
    <>
      <title>File upload</title>
      <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
      <meta charSet="UTF-8" />
      <link
        rel="icon"
        type="image/png"
        href="resource/kailaasa-favicon-32x32.png"
      />
      <link rel="stylesheet" href="assets/styles.css" type="text/css" />
      <link rel="stylesheet" href="assets/menu.css" type="text/css" />
      <link
        rel="stylesheet"
        href="assets/flatpickr-4.6.9.min.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="assets/select2-4.0.13.min.css"
        type="text/css"
      />
    </>
  );
}

function footer() {}

function App() {
  let [isLoading, setLoading] = useState(false);

  let [email, setEmail] = useState(null);
  let [startDate, setStartDate] = useState(new Date());
  let [place, setPlace] = useState("Adikailasa AK");
  let [presidentialBriefing, setPresidentialBriefing] = useState(null);
  let [eventType, setEventType] = useState("Daily Rituals (Nithyotsavam)");
  let [eventName, setEventName] = useState(null);
  let [activityType, setActivityType] = useState([]);
  let [volunteerCount, setVolunteerCount] = useState(null);
  let [livesEnriched, setLivesEnriched] = useState(null);
  let [description, setDescription] = useState(null);
  let [files, setFiles] = useState(null);

  const handleClick = (e) => {
    // let target = e.target;
    // let data = { email, startDate, place, presidentialBriefing, eventType, eventName, activityType, volunteerCount, livesEnriched, description }

    if (
      email != null &&
      startDate != null &&
      description != null &&
      files != null
    ) {
      setLoading(true);
      let data = new FormData();
      data.append("email", email);
      data.append("startDate", startDate);
      data.append("place", place);

      data.append("presidentialBriefing", presidentialBriefing);
      data.append("eventType", eventType);
      data.append("eventName", eventName);
      data.append("activityType", activityType);
      data.append("volunteerCount", volunteerCount);
      data.append("livesEnriched", livesEnriched);
      data.append("description", description);

      let input = document.querySelector('input[type="file"]');
      console.log("files...", input.files, files);
      for (let i = 0; i < files.length; i++) {
        data.append(`files`, files[i], files[i].name);
      }
      ////http://localhost:3000
      //https://npediaimg.koogle.sk
      fetch("https://npediaimg.ecitizen.info/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(JSON.stringify(data));
          setLoading(false);
        })
        .catch((err) => {
          alert(JSON.stringify(err));
          setLoading(false);
        });
    } else {
      alert("Please fill all the mandatory fields");
    }
  };
  return (
    <>
      <Header />
      <title>Kailasapedia Form</title>
      <div className="menu">
        <div className="menu_content">
          <img className="menu_logo_img" src="assets/kailasa-logo.png" />
          <span>Kailasapedia Form</span>
          <img className="menu_logo_img" src="assets/swamiji.png" />
        </div>
      </div>
      {isLoading ? (
        <center>
          <br></br>
          <br></br>
          <HashLoader color="orange" size={600} />
          <br></br>Uploading
        </center>
      ) : (
        <form id="upload">
          <div className="email_id input_container">
            <label htmlFor="email_id">Email id</label>
            <input
              type="email"
              id="email_id"
              name="email_id"
              required
              inputMode="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <span id="email_id_helptext" className="helptext">
              Email id of the person or Kailasa entity submitting this form.
              Please enter a valid email,{" "}
              <strong>which you have access to</strong>. Please note, later if
              you register an account with this email id, you will be able to
              find all images you uploaded with this email id. Ex. -
              arsjp@kailaasa.org, newjerseysatsangcenter@gmail.com,
              nithyagautama@yahoo.com etc.
            </span>
            <span className="denote_required">
              This input field is mandatory.
            </span>
          </div>
          <div>
            <label htmlFor="date">Date of Images</label>
            <DatePicker
              id="date"
              name="date"
              selected={startDate}
              onChange={(startDate) => setStartDate(startDate)}
            />
            <span id="date_helptext" className="helptext">
              Select the actual date when this event took place, and the
              photographs/images were captured.
            </span>
            <span className="denote_required">
              This input field is mandatory.
            </span>
          </div>
          <div className="entity">
            <label htmlFor="entity">Place</label>
            <select
              id="entity"
              name="entity"
              data-select2-id="entity"
              tabIndex={-1}
              className="select2-accessible"
              aria-hidden="true"
              onChange={(e) => {
                setPlace(e.target.value);
              }}
            >
               <option value="ADIKAILASA" data-select2-id={2} selected="selected">ADIKAILASA</option>
               <option value="KAILASA AVADI CHENNAI">KAILASA AVADI CHENNAI</option>
<option value="KAILASA SALEM">KAILASA SALEM</option>
<option value="KAILASA TIRUVANNAMALAI SRI NITHYANANDA JANMABHUMI">KAILASA TIRUVANNAMALAI SRI NITHYANANDA JANMABHUMI</option>
<option value="KAILASA TIRUVANNAMALAI SRI NITHYANANDA JANMABHUMI KSHETRAM">KAILASA TIRUVANNAMALAI SRI NITHYANANDA JANMABHUMI KSHETRAM</option>
<option value="KAILASA TANJAVUR">KAILASA TANJAVUR</option>
<option value="KAILASA PRAYAGRAJ">KAILASA PRAYAGRAJ</option>
<option value="KAILASA HOSUR">KAILASA HOSUR</option>
<option value="KAILASA MADURAI">KAILASA MADURAI</option>
<option value="KAILASA SEERAGAPADI">KAILASA SEERAGAPADI</option>
<option value="KAILASA THIRUCHENGODE">KAILASA THIRUCHENGODE</option>
<option value="KAILASA RAJAPALAYAM">KAILASA RAJAPALAYAM</option>
<option value="KAILASA KORATTUR CHENNAI">KAILASA KORATTUR CHENNAI</option>
<option value="KAILASA PORUR CHENNAI">KAILASA PORUR CHENNAI</option>
<option value="KAILASA COIMBATORE">KAILASA COIMBATORE</option>
<option value="KAILASA KELAMBAKKAM">KAILASA KELAMBAKKAM</option>
<option value="KAILASA PATTABIRAM">KAILASA PATTABIRAM</option>
<option value="KAILASA POONAMALLE">KAILASA POONAMALLE</option>
<option value="KAILASA TIRUNINRAVUR">KAILASA TIRUNINRAVUR</option>
<option value="KAILASA VANIYAMBADI">KAILASA VANIYAMBADI</option>
<option value="KAILASA KANCHIPURAM">KAILASA KANCHIPURAM</option>
<option value="KAILASA SETHUR">KAILASA SETHUR</option>
<option value="KAILASA TIRUVANMAYUR">KAILASA TIRUVANMAYUR</option>
<option value="KAILASA TIRUVANMAYUR">KAILASA TRISHULAM</option>
<option value="KAILASA PATTANAM">KAILASA PATTANAM</option>
<option value="KAILASA ALANGULAM">KAILASA ALANGULAM</option>
<option value="KAILASA CHITLAPAKKAM">KAILASA CHITLAPAKKAM</option>
<option value="KAILASA THONDAIYARPET">KAILASA THONDAIYARPET</option>
<option value="KAILASA UTTARAHALLI BANGALORE">KAILASA UTTARAHALLI BANGALORE</option>
<option value="KAILASA BHANASHANKARI BANGALORE">KAILASA BHANASHANKARI BANGALORE</option>
<option value="KAILASA BHASVESHWARNAGAR BANGALORE">KAILASA BHASVESHWARNAGAR BANGALORE</option>
<option value="KAILASA HYDERABAD INDIA">KAILASA HYDERABAD INDIA</option>
<option value="KAILASA HARIDWAR INDIA">KAILASA HARIDWAR INDIA</option>
<option value="KAILASA PONDICHERRY INDIA">KAILASA PONDICHERRY INDIA</option>
<option value="KAILASA KASHI INDIA">KAILASA KASHI INDIA</option>
<option value="KAILASA ICHAPURAM INDIA">KAILASA ICHAPURAM INDIA</option>
<option value="KAILASA NEW JERSEY USA">KAILASA NEW JERSEY USA</option>
<option value="KAILASA SAN DIEGO USA">KAILASA SAN DIEGO USA</option>
<option value="KAILASA DALLAS USA">KAILASA DALLAS USA</option>
<option value="KAILASA HOUSTON USA">KAILASA HOUSTON USA</option>
<option value="KAILASA LOS ANGELES USA">KAILASA LOS ANGELES USA</option>
<option value="KAILASA MIAMI USA">KAILASA MIAMI USA</option>
<option value="KAILASA OHIO USA">KAILASA OHIO USA</option>
<option value="KAILASA OKLAHOMA USA">KAILASA OKLAHOMA USA</option>
<option value="KAILASA PHOENIX USA">KAILASA PHOENIX USA</option>
<option value="KAILASA SAN JOSE USA">KAILASA SAN JOSE USA</option>
<option value="KAILASA SEATTLE USA">KAILASA SEATTLE USA</option>
<option value="KAILASA ST. LOUIS USA">KAILASA ST. LOUIS USA</option>
<option value="KAILASA JAMAICA USA">KAILASA JAMAICA</option>
<option value="KAILASA KENT UNITED KINGDOM">KAILASA KENT UNITED KINGDOM</option>
<option value="KAILASA LONDON UNITED KINGDOM">KAILASA LONDON UNITED KINGDOM</option>
<option value="KAILASA GENEVA SWITZERLAND">KAILASA GENEVA SWITZERLAND</option>
<option value="KAILASA BADEN SWITZERLAND">KAILASA BADEN SWITZERLAND</option>
<option value="KAILASA UTRECHT NETHERLANDS">KAILASA UTRECHT NETHERLANDS</option>
<option value="KAILASA FLORENCE ITALY">KAILASA FLORENCE ITALY</option>
<option value="KAILASA CROATIA">KAILASA CROATIA</option>
<option value="KAILASA PARIS FRANCE">KAILASA PARIS FRANCE</option>
<option value="KAILASA DORDOGNE FRANCE">KAILASA DORDOGNE FRANCE</option>
<option value="KAILASA REUNION FRANCE">KAILASA REUNION FRANCE</option>
<option value="KAILASA MELBOURNE AUSTRALIA">KAILASA MELBOURNE AUSTRALIA</option>
<option value="KAILASA WYHALLA AUSTRALIA">KAILASA WYHALLA AUSTRALIA</option>
<option value="KAILASA ROTORUA NEW ZEALAND">KAILASA ROTORUA NEW ZEALAND</option>
<option value="KAILASA WELLINGTON NEW ZEALAND">KAILASA WELLINGTON NEW ZEALAND</option>
<option value="KAILASA KUALA LUMPUR MALAYSIA">KAILASA KUALA LUMPUR MALAYSIA</option>
<option value="KAILASA PUCHONG MALAYSIA">KAILASA PUCHONG MALAYSIA</option>
<option value="KAILASA SELAYANG MALAYSIA">KAILASA SELAYANG MALAYSIA</option>
<option value="KAILASA SINGAPORE">KAILASA SINGAPORE</option>
<option value="KAILASA MONTREAL CANADA">KAILASA MONTREAL CANADA</option>
<option value="KAILASA TORONTO CANADA">KAILASA TORONTO CANADA</option>
<option value="KAILASA PORTUGAL">KAILASA PORTUGAL</option>
<option value="KAILASA TRINIDAD & TOBAGO">KAILASA TRINIDAD & TOBAGO</option>
<option value="KAILASA BAIE MAHAULT GUADELOUPE">KAILASA BAIE MAHAULT GUADELOUPE</option>
<option value="KAILASA GOYAVE GUADELOUPE">KAILASA GOYAVE GUADELOUPE</option>
<option value="KAILASA BAHRAIN">KAILASA BAHRAIN</option>
<option value="KAILASA DUBAI">KAILASA DUBAI</option>
<option value="KAILASA OMAN">KAILASA OMAN</option>
<option value="KAILASA GAMBIA WEST AFRICA">KAILASA GAMBIA WEST AFRICA</option>
<option value="KAILASA SIERRA LEONE AFRICA">KAILASA SIERRA LEONE AFRICA</option>
<option value="KAILASA BELGIUM">KAILASA BELGIUM</option>
<option value="KAILASA LUXEMBOURG">KAILASA LUXEMBOURG</option>
<option value="KAILASA CHINESE WORLD">KAILASA CHINESE WORLD</option>
<option value="KAILASA SUVA FIJI">KAILASA SUVA FIJI</option>
<option value="KAILASA YSJP3">KAILASA YSJP3</option>
<option value="KAILASA BOLIVIA">KAILASA BOLIVIA</option>
<option value="KAILASA BOLIVIA">KAILASA ECUADOR</option>
</select>
            {/* <span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="1"
          style="width: 628.85px;"><span class="selection"><span
                  class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
                  aria-expanded="false" tabindex="0" aria-disabled="false"
                  aria-labelledby="select2-entity-container"><span class="select2-selection__rendered"
                      id="select2-entity-container" role="textbox" aria-readonly="true"
                      title="Adikailasa AK">Adikailasa AK</span><span class="select2-selection__arrow"
                      role="presentation"><b role="presentation"></b></span></span></span><span
              class="dropdown-wrapper" aria-hidden="true"></span></span> */}
            <span id="entity_helptext" className="helptext">
              Please select the KAILASA entity for which you are submitting
              these images. If your KAILASA entity is not in the list, please
              contact NPedia/KPedia team.{" "}
            </span>
          </div>

          <div className="event_type">
            <label htmlFor="event_type">Event Type</label>
            <select
              id="event_type"
              name="event_type"
              className=""
              aria-hidden="true"
              onChange={(e) => {
                setEventType(e.target.value);
              }}
            >
              <option value="nithyotsavam" selected="selected">
                Daily Rituals (Nithyotsavam)
              </option>
              <option value="utsavam">Festivals / Utsava</option>
              <option value="brahmotsavam">Brahmotsavam</option>
              <option value="seva">Special Ritual</option>
              <option value="yoga">Yoga Session</option>
              <option value="pm">Power Manifestation Session</option>
              <option value="enriching">Enriching Session</option>
              <option value="causing">Causing Session</option>
              <option value="program">Program or Jnana classes</option>
              <option value="darshan">Darshan of the SPH</option>
              <option value="annadaan">Aushadha Anna daan</option>
              <option value="anniversary">Anniversary</option>
              <option value="inauguration">Inaugration</option>
              <option value="sangha">Sangha meeting</option>
              <option value="dr">
                Diplomatic Meeting, outreach or recognition
              </option>
              <option value="cultural">Cultural Event or gathering</option>
              <option value="secular">
                National or state event or holiday
              </option>
              <option value="work">General work</option>
              <option value="humanitarian">
                Humanitarian services or disaster relief work
              </option>
              <option value="persecution">Persecution or attack</option>
              <option value="emergency">Emergency or natural calamity</option>
              <option value="media">Media positive coverage</option>
            </select>
          </div>
          <div className="event_name input_container">
            <label htmlFor="event_name">Event Name</label>
            <input
              type="text"
              id="event_name"
              name="event_name"
              inputMode="text"
              onChange={(e) => {
                setEventName(e.target.value);
              }}
            />
            <span id="event_name_helptext" className="helptext">
              Type the name of the event, example : Padapuja, Gurupurnima, etc.
              Allowed characters : alphabets, numbers, space, underscore, dash,
              fullstop, comma, brackets, hashsymbol
            </span>
          </div>
          <div className="activity_type">
            <label htmlFor="activity_type">Activity Type</label>
            <select
              id="activity_type"
              name="activity_type"
              multiple="multiple"
              data-select2-id="activity_type"
              tabIndex={-1}
              className=""
              aria-hidden="true"
              onChange={(e) => {
                console.log(activityType);
                setActivityType(
                  Array.from(e.target.selectedOptions).map((v) => v.value)
                );
              }}
            >
              <option value="ritual">Puja and Rituals</option>
              <option value="offering">Offering Services or Sevas</option>
              <option value="session">Jnana Session</option>
              <option value="meeting">Meeting</option>
              <option value="yoga">Yoga Session</option>
              <option value="healing">Spiritual Healing</option>
              <option value="medical">Medical services</option>
              <option value="cleaning">Cleaning</option>
              <option value="construction">Construction or fabrication</option>
              <option value="maintenance">Maintenance</option>
              <option value="horticulture">
                Gardening, Agriculture, Horticulture
              </option>
              <option value="firesaftey">Fire prevention work</option>
              <option value="emergency">Emergency Activity</option>
              <option value="organizing">Organizing or Inventory</option>
              <option value="verification">Accounts verification</option>
              <option value="dance">Traditional Dance</option>
              <option value="arts">Traditional Arts</option>
              <option value="cooking">Cooking</option>
              <option value="gaushala">Gaushala Seva</option>
              <option value="feeding">Serving or offering food</option>
              <option value="crowd">Gatherings or crowd</option>
              <option value="waiting">Waiting devotees</option>
              <option value="swamiji">Swamiji the SPH</option>
              <option value="pm">Power Manifestation</option>
              <option value="office">Office work</option>
              <option value="physical">Physical work</option>
              <option value="decoration">Decoration work</option>
              <option value="alankaram">Deity Alankaram</option>
              <option value="preparation">Preparation</option>
              <option value="parayanam">Parayanam chanting</option>
              <option value="enriching_outreach">
                Enriching &amp; Outreach Activities
              </option>
              <option value="dr_outreach">Diplomatic outreach</option>
              <option value="dr_treaty">Diplomatic Treaty Signing</option>
              <option value="fundraising">Causing or Fundraising</option>
              <option value="procession">Procession</option>
              <option value="pada_yatra">Pada Yatra</option>
              <option value="minutes4peace">Meditation for peace</option>
              <option value="security">Security</option>
            </select>

            <span id="activity_type_helptext" className="helptext">
              Multiple activities may be selected.
            </span>
          </div>
          <div className="volunteer_count input_container">
            <label htmlFor="volunteer_count">Volunteer count</label>
            <input
              type="number"
              id="volunteer_count"
              name="volunteer_count"
              min={0}
              step={1}
              defaultValue={0}
              required
              onChange={(e) => {
                setVolunteerCount(e.target.value);
              }}
            />
            <span id="volunteer_count_helptext" className="helptext">
              Enter here the total number of volunteers/monks who directly
              worked for this event
            </span>
          </div>
          <div className="lives_enriched input_container">
            <label htmlFor="lives_enriched">Lives enriched</label>
            <input
              type="number"
              id="lives_enriched"
              name="lives_enriched"
              min={0}
              step={1}
              defaultValue={0}
              required
              onChange={(e) => {
                setLivesEnriched(e.target.value);
              }}
            />
            <span id="lives_enriched_helptext" className="helptext">
              Enter here the total number of people amongst the general public
              who were benefited/enriched during this event
            </span>
          </div>
          <div className="description textarea_container">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              rows={5}
              cols={50}
              required
              defaultValue={""}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <span id="pd_brief_helptext" className="helptext">
              <p>
                <strong>Description</strong> is a concise reports providing
                significant and publicly reportable achievements of your KAILASA
                entity to Swamiji - the SPH JGM HDH Bhagavan Nithyananda
                Paramashivam and KAILASA. These will be used as input for
                newsletters and other publications. Please submit these reports
                per the latest format, and usually it is mandatory to include
                numbers and statistics, some examples:
              </p>
              <ul>
                <li>
                  200 people were served anna daan, including 10 policemen, and
                  5 public healthcare workers.
                </li>
                <li>1008 Naivedyams offered on the occasion of Guru Purnima</li>
              </ul>
            </span>
            <span id="description_helptext" className="helptext">
              Give a complete description of the event, in as few words as
              possible.
            </span>
            <span className="denote_required">
              This input field is mandatory.
            </span>
          </div>
          <div className="filesselect">
            <label htmlFor="files">Select files</label>
            <input
              type="file"
              id="files"
              name="files"
              accept=".jpg, .jpeg, .png, .webp"
              multiple
              required
              onChange={(e) => {
                setFiles(e.target.files);
              }}
            />
            <span id="files_helptext" className="helptext">
              Select images files and photographs of the event to upload
            </span>
            <span className="denote_required">
              This input field is mandatory.
            </span>
          </div>

          <button
            onClick={handleClick}
            disabled={isLoading}
            className={isLoading ? "disabled" : ""}
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </form>
      )}
      <grammarly-desktop-integration data-grammarly-shadow-root="true" />
    </>
  );
}

export default App;
